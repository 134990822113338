export default function Traction() {
    return(
        <section className="bg-layer-1 p-6 md:py-10">
            <div className="mx-auto w-full max-w-6xl">
              <p className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">
                Traction
              </p>
              <ul className="mx-auto mt-2 flex flex-wrap justify-center ">

                <li className="mt-4 mx-8 hover:opacity-80">
                    <div className="rounded-xl w-52 text-center border-4 p-8 border-solid border-muted-1">
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">MEMBERSHIP GROWTH</h3>
                        <h2 className="mx-auto mt-4 text-center text-3xl font-rajdhani tracking-tight text-heading md:max-w-2xl md:text-4xl">
                            35,000+
                        </h2>
                    </div>
                </li>

                <li className="mt-4 mx-8 hover:opacity-80">
                    <div className="rounded-xl w-52 text-center border-4 p-8 border-solid border-muted-1">
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">SUCCESSFUL ENTREPRENEURS</h3>
                        <h2 className="mx-auto mt-4 text-center text-3xl font-rajdhani tracking-tight text-heading md:max-w-2xl md:text-4xl">
                            1,500+
                        </h2>
                    </div>
                </li>

                <li className="mt-4 mx-8 hover:opacity-80">
                    <div className="rounded-xl w-52 text-center border-4 p-8 border-solid border-muted-1">
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">SUCCESS STORIES</h3>
                        <h2 className="mx-auto mt-4 text-center text-3xl font-rajdhani tracking-tight text-heading md:max-w-2xl md:text-4xl">
                            100+
                        </h2>
                    </div>
                </li>

                <li className="mt-4 mx-8 hover:opacity-80">
                    <div className="rounded-xl w-52 text-center border-4 p-8 border-solid border-muted-1">
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">COLLABORATIVE PROJECT</h3>
                        <h2 className="mx-auto mt-4 text-center text-3xl font-rajdhani tracking-tight text-heading md:max-w-2xl md:text-4xl">
                            20+
                        </h2>
                    </div>
                </li>

              </ul>
            </div>
          </section>
    )
}