import Header from "./header";
import Hero from "./hero";
import Support from "./support";
import Who from "./who";
import Feature from "./feature";
import Stories from "./stories";
import Changes from "./changes";
import Footer from "./footer";
import Traction from "./traction";


export default function Home() {
    return (
      <div>
        <Header />
        <main>
          <Hero />
          <Support />  
          <Who />
          <Feature />
          <Traction />
          <Stories />
          <Changes />
        </main>
        <Footer />
      </div>
    );
  }
  