export default function Who() {
    return(
        <section className="p-8 md:py-20" id="about">
            <div className="mx-auto max-w-5xl md:px-4">
                <h2 className="text-4xl font-rajdhani font-bold text-heading md:text-5xl text-center">
                    WHO WE ARE
                </h2>
                <p className="mt-8 text-2xl font-rajdhani  text-heading md:text-2xl text-center">
                Squad - E is a dedicated organization committed to the development, training, and
                empowerment of entrepreneurs, with a particular focus on women entrepreneurs. We
                provide comprehensive programs and personalized support to equip our members with
                the essential skills and resources needed for business success. Our vibrant community
                and tailored services foster an environment where entrepreneurs can thrive.
                </p>
            </div>
        </section>
    )
}