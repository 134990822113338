export default function Changes() {
    return(
        <section className="px-8 py-12 md:py-18">
            <div className="mx-auto max-w-6xl space-y-24 md:space-y-36">
                <h2 className="text-4xl font-rajdhani font-bold text-heading md:text-5xl text-center">
                    STORIES OF CHANGES
                </h2>
                <div className="flex flex-col items-center justify-between gap-6 lg:flex-row ">
                <div className="max-w-md space-y-6 text-center lg:text-left">
                    <h3 className="text-3xl font-rajdhani  text-heading lg:text-4xl">
                        Breaking Barriers: Tasmia Rahman Arpita's Entrepreneurial Odyssey
                    </h3>
                    <p className="text-lg font-medium">
                        Tasmia Rahman Arpita, a Microbiology graduate, launched "Multan JUNKY by ARPI" during the
                        2020 pandemic. Overcoming challenges, she built a thriving community of 16,000 members
                        and expanded her team. Her journey empowers women to pursue their dreams and redefine
                        success.
                    </p>
                </div>
                <div className="max-w-lg">
                    <img src="./ent/1.png" alt="" className="w-full" />
                </div>
                </div>
                <div className="flex flex-col items-center justify-between gap-6 lg:flex-row-reverse">
                <div className="max-w-md space-y-6 text-center lg:text-left">
                    <h3 className="text-3xl font-rajdhani  text-heading lg:text-4xl">
                        Breaking Barriers: Tasnia Rahman's Entrepreneurial Journey
                    </h3>
                    <p className="text-lg font-medium">
                        In 2017, Tasnia Rahman turned her Facebook time into a business, founding Tasnia’s Mart.
                        Despite societal pressure and initial setbacks, she revived her business in 2021 with her
                        husband's support. Tasnia now inspires women to pursue their dreams and defy societal
                        norms.
                    </p>
                </div>
                <div className="max-w-lg">
                    <img src="./ent/2.png" alt="" className="w-full" />
                </div>
                </div>

                <div className="flex flex-col items-center justify-between gap-6 lg:flex-row ">
                <div className="max-w-md space-y-6 text-center lg:text-left">
                    <h3 className="text-3xl font-rajdhani  text-heading lg:text-4xl">
                        Breaking Barriers: Sabrina Mumu's Entrepreneurial Journey
                    </h3>
                    <p className="text-lg font-medium">
                        Sabrina Mumu, a BBA student, founded "Girls Mirror" in 2019, overcoming family expectations
                        and COVID-19 challenges. Her passion for cosmetics and jewelry turned into a successful
                        business, inspiring many women. Sabrina’s story exemplifies determination and resilience in
                        the face of adversity.
                    </p>
                </div>
                <div className="max-w-lg">
                    <img src="./ent/3.png" alt="" className="w-full" />
                </div>
                </div>

                <div className="flex flex-col items-center justify-between gap-6 lg:flex-row-reverse">
                <div className="max-w-md space-y-6 text-center lg:text-left">
                    <h3 className="text-3xl font-rajdhani  text-heading lg:text-4xl">
                        Empowering Women Entrepreneurs: The Shaila Islam Story
                    </h3>
                    <p className="text-lg font-medium">
                        Discover the remarkable journey of Shaila Islam, a BBA student turned entrepreneur, who
                        defied obstacles to build her successful online business. From modeling to blogging to
                        founding her own venture, Shaila's resilience and determination inspire women worldwide.
                        asnia now inspires women to pursue their dreams and defy societal
                        norms.
                    </p>
                </div>
                <div className="max-w-lg">
                    <img src="./ent/4.png" alt="" className="w-full" />
                </div>
                </div>
            </div>
        </section>
    )
}