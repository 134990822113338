export default function Feature() {
    return(
        <div className="w-full bg-layer-1 p-6 text-center md:p-20" id="programs">
            <div className="mx-auto w-full max-w-6xl">
                <h2 className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">
                    WHAT WE DO
                </h2>

                <h2 className="mx-auto mt-4 text-center text-3xl font-rajdhani tracking-tight text-heading md:max-w-2xl md:text-4xl">
                    At Squad - E, we offer a variety of services to support the growth and success of our members' ventures
                </h2>
                <div className="mt-12 grid grid-cols-1 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                    <div className="p-5 text-center md:p-8">
                        <div className="inline-block rounded-3xl bg-layer-2 py-4 px-4">
                            <h2 className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">1</h2>
                        </div>
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">
                        Tailored Training Programs
                        </h3>
                        <p className="mt-2 text-center text-lg text-text">
                        Our customized training programs are designed to meet the unique needs of each member, providing essential skills and knowledge to drive their businesses forward.
                        </p>
                    </div>

                    <div className="p-5 text-center md:p-8">
                        <div className="inline-block rounded-3xl bg-layer-2 py-4 px-4">
                        <h2 className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">2</h2>
                        </div>
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">
                            Business Growth Strategies
                        </h3>
                        <p className="mt-2 text-center text-lg text-text">
                            We offer expert guidance and strategies to help your business expand, ensuring sustainable growth and long-term success.
                        </p>
                    </div>

                    <div className="p-5 text-center md:p-8">
                        <div className="inline-block rounded-3xl bg-layer-2 py-4 px-4">
                        <h2 className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">3</h2>
                        </div>
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">
                            Branding Services
                        </h3>
                        <p className="mt-2 text-center text-lg text-text">
                            Our branding services help you create a strong, memorable identity that resonates with your target audience and sets you apart from the competition.
                        </p>
                    </div>

                    <div className="p-5 text-center md:p-8">
                        <div className="inline-block rounded-3xl bg-layer-2 py-4 px-4">
                        <h2 className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">4</h2>
                        </div>
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">
                            Sales and Marketing Plans
                        </h3>
                        <p className="mt-2 text-center text-lg text-text">
                            We develop effective sales and marketing plans tailored to your specific goals, maximizing your reach and driving revenue.
                        </p>
                    </div>

                    <div className="p-5 text-center md:p-8">
                        <div className="inline-block rounded-3xl bg-layer-2 py-4 px-4">
                        <h2 className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">5</h2>
                        </div>
                        <h3 className="mt-4 text-xl font-rajdhani  text-heading">
                        Networking and Collaboration
                        </h3>
                        <p className="mt-2 text-center text-lg text-text">
                        We provide opportunities for networking and collaboration, connecting you with like-minded professionals and potential partners to accelerate your venture's growth.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}