export default function Footer() {
    return(
        <footer className="px-4 pt-10 pb-18" id="social">
            <div className="mx-auto flex max-w-6xl flex-col items-center justify-between space-y-4 lg:flex-row lg:space-y-0">
                <div className="flex flex-col items-center justify-center space-x-0 space-y-4 lg:flex-row lg:space-x-14 lg:space-y-0">
                    <a href="/">
                        <div className="h-7 text-heading mb-14">
                            <img src="./logo.png" alt="logo" className="w-24" />
                        </div>
                    </a>

                    <div className="flex flex-col space-y-2 text-center text-sm font-rajdhani  lg:flex-row lg:space-x-6 lg:space-y-0 lg:text-left">
                        <span>Copyright &copy; 2024 Squad-E • All rights reserved</span>
                        <a href="#career" className="font-rajdhani  text-text hover:text-heading">
                            Career
                        </a>
                        <a href="#programs" className="font-rajdhani  text-text hover:text-heading">
                            Programs
                        </a>
                        <a href="mailto:info@squad-e.org" className="font-rajdhani  text-text hover:text-heading">
                            info@squad-e.org
                        </a>
                    </div>
                </div>

                <div className="flex space-x-4">
                    <a href="https://www.facebook.com/share/oBHpmJF5NVkdGyAB/?mibextid=A7sQZp">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"/><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"/></svg>
                    </a>
                    <a href="https://www.facebook.com/SEntrepreneurs.2022?mibextid=JRoKGi">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"/><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"/></svg>
                    </a>
                </div>
            </div>
        </footer>
    )
}