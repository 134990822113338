import axios from "axios"
import { useState } from "react"

export default function Hero() {
  const [email, setEmail] = useState('')
  const submit = () => {
    axios.get(`https://api.callmebot.com/whatsapp.php?apikey=2649612&phone=8801726756399&text=A+person+wants+to+get+updates+of+Squad-e+through+${email}`)
    .then((res) => {

    }).catch((err) => {

    })
  }
    return(
        <section className="px-8 pt-6 pb-2 text-center md:py-16">
        <div className="mx-auto max-w-6xl">
          <div className="flex flex-col items-center">
            <h1 className="text-center text-3xl font-rajdhani font-bold text-heading md:max-w-4xl lg:text-5xl xl:text-6xl">
                Create a world where entrepreneurship is accessible to all, driving innovation, economic growth, and positive social change
            </h1>
            <p className="mt-6 max-w-3xl text-xl">
                Empower entrepreneurs, especially women, through comprehensive training and support, fostering a thriving ecosystem for business success.
            </p>
            <form className="mt-6 flex flex-col gap-2 sm:flex-row" onSubmit={(e) => e.preventDefault()}>
              <div>
                <label htmlFor="email" className="sr-only block text-sm font-rajdhani  text-heading" >
                  Email
                </label>
                <input id="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder="Email" className="block w-72 w-full rounded-xl border-2 border-layer-3 bg-muted-1 px-4 py-2.5 font-rajdhani  text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"/>
              </div>
              <button type="submit" onClick={() => submit()} className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none bg-gradient-to-r from-fuchsia-600 to-indigo-600 px-4 py-2.5 text-sm font-rajdhani  text-white transition duration-200 hover:bg-gradient-to-r hover:from-fuchsia-600 hover:to-indigo-500 focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:opacity-30 disabled:hover:text-white dark:focus:ring-white/80">
                Next
              </button>
            </form>
          </div>
          <div className="mt-12 md:px-2 ">
            <img src="./ent/cover.png" alt="logo" className="rounded-3xl" />
          </div>
        </div>
      </section>
    )
}