export default function Support() {
    return(
        <section className="bg-layer-1 p-6 md:py-10" id="contact">
            <div className="mx-auto w-full max-w-6xl">
              <p className="text-4xl font-rajdhani text-heading md:text-5xl text-center font-bold">
                SUPPORTED BY
              </p>
              <ul className="mx-auto mt-2 flex flex-wrap justify-center space-x-4">
                    {
                        [1,2,3,4].map((item, index) => (
                            <li className="mt-4 mx-8 hover:opacity-80">
                                <div className="rounded-xl border-4 p-8 border-solid border-muted-1">
                                        <img src={"./" + item + ".png"} alt="logo" key={index} className="h-10" />
                                </div>
                            </li>
                        ))
                    }
              </ul>
            </div>
          </section>
    )
}