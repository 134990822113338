export default function Stories() {
    return(
        <section className="p-8 md:py-20">
            <div className="mx-auto max-w-5xl md:px-4">
                <h2 className="text-4xl font-rajdhani font-bold text-heading md:text-5xl text-center">
                    OUR STORIES
                </h2>
                <p className="mt-8 text-2xl font-rajdhani  text-heading md:text-2xl text-center">
                    Squad-E's journey started in September 2022, with a vision to revolutionize
                    entrepreneurship and empower individuals to pursue their business dreams. Over the
                    years, we've evolved and adapted to meet the changing needs of our community,
                    expanding our programs, partnerships, and services to better serve our members. Today,
                    Squad - E stands as a beacon of hope and empowerment for entrepreneurs worldwide,
                    inspiring individuals to dream big, take risks, and pursue their passions with confidence. <br /> <br />
                    The story of Squad - E is a testament to the transformative power of entrepreneurship
                    and the boundless potential of those who dare to dream. And this is just the beginning of
                    our story—a story that continues to unfold with each new entrepreneur we empower and
                    each new milestone we achieve.
                </p>
            </div>
        </section>
    )
}